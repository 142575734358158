import { ISessionPayload } from "@guardian/Components/ModSOS/components/Dashboard/SOSDashboard.types"

export interface IState {
  sessionResp?: IResponse
  activeSessionId?: string
  isV2Protect?: boolean
  callSession?: ISessionPayload
  sessionRecords?: IResponse
  ownerData?: IResponse
  errorModal?: {
    type: TErrorModal
    metadata: any
  }
  is911InSession?: boolean
}

export interface IRecord {
  location: {
    addressGeneral: string
    addressSpecific: string
    automotive?: unknown
    background: false
    bearing: number
    cycling?: unknown
    device: string
    elevation: number
    horizontalAccuracy: number
    latitude: number
    locationTimestamp: number
    locationUserId: string
    longitude: number
    motionConfidence?: unknown
    motionStart?: unknown
    nearbyRadius: number
    running?: unknown
    speed: number
    stationary?: unknown
    usersNearby: number
    verticalAccuracy: number
    walking?: unknown
  }
  batteryLevel: number
  presence: string
  timestamp: string
  uuid: string
  heartRate?: number
}

export interface IOutgoingCall {
  to: string
  connectionId: string
  fromNumber: string
  createdAt: string
}

export interface IOwnerData {
  outgoingCall?: IOutgoingCall
  subscriptionType: "free_beta"
  subscriptionStatus: "paid"
  subscriptionBillingPeriod: unknown
  subscriptionPrice: number
  subscriptionDescription: string
  subscriptionExpiresDate: string
  subscriberSince: string
  previousCalls: number
  name: string
  phone: string
  sessionId: string
  requested911: "yes" | "no"
  emergencyContacts: unknown[]
  offPlatformEmergencyContacts: unknown[]
  joinDate: string
  safetyProfile: {
    userID: string
    homeLocation: {
      searchId: string
      addressSpecific: string
      addressGeneral: string
      latitude: number
      longitude: number
    }
  }
  platform: "IOS" | "Android"
  geocodedNumbers: unknown[]
  serviceArea: null
  hasLocationPermission: true
  userSettings: {
    camera_allowed: boolean
    contacts_allowed: boolean
    location_always_on: boolean
    location_while_using: boolean
    microphone_allowed: boolean
    notifications_allowed: boolean
  }
  wmbSessionUrl?: string
}

export interface IResponse {
  data?: any
  error?: string
}

export enum TErrorModal {
  PromptUserInteraction = "prompt user interaction",
  CallTaken = "call taken",
}

export enum TSOSDashboardContextActionType {
  UpdateActiveSessionId = "update active session id",
  UpdateSessionResp = "update session resp",
  UpdateCallSession = "update call session",
  UpdateSessionRecords = "update session records",
  Reset = "reset",
  ShowErrorModal = "show error modal",
  CloseErrorModal = "close error modal",
  UpdateOwnerData = "update owner data",
  Update911InSession = "update 911 in session",
}

export interface IAction {
  type: TSOSDashboardContextActionType
  data?: any
}
