export interface IProps {
	videoDeviceId: string
}

export interface IEmergencyContact {
  avatarColor: string
  avatarIsDefault: boolean
  avatarThumbURL: string
  avatarURL: string
  fullName: string
  hiddenFrom: boolean
  id: string
  initials: string
  joined: string
  priority: boolean
  shortName: string
  username: string
}

export interface IOffPlatformEmergencyContact {
  fullName: string
  contact_id: string
}

interface ISessionPayloadMetadata {
  activeWMB25SessionID?: string
  fullName: string
  createdAt: string
  analystName: string
  active911Call: string
  isFirstCall?: boolean
  isMissedCall?: boolean
  entryPointType?: string
  agentUserId?: string
  isNonSubscriber?: boolean
  mode?: string
}

export interface ISessionPayload {
  id: string
  status: string
  metadata: ISessionPayloadMetadata
}

export enum TScriptType {
  Section = "section",
  Note = "note",
  Zingtree = "zingtree",
}
